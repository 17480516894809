@import './variables';
@import './overrides/bootstrap';
@import './overrides/crowdsignal';
@import './overrides/disqus';
@import './overrides/mediavine';
@import './overrides/mtgh';
@import './overrides/nprogress';
@import './overrides/react-bootstrap-table';
@import './overrides/react-bootstrap-typeahead';
@import './overrides/recharts';
@import './overrides/wordpress';
@import '~bootstrap/scss/bootstrap';

:root,
[data-bs-theme='light'] {
  --body-bg: #{$gray-100};
  --link-color-visited: #{$link-color-visited};
}

@include color-mode(dark, true) {
  --body-bg: #{$gray-950};
  --link-color-visited: #{$link-color-visited-dark};
}

body {
  background-color: $gray-800;
}

code {
  font-family: 'Source Code Pro', monospace;
  white-space: pre-wrap;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 29px - 26px); // 29px for Mediavine "Do not sell or share my information" link, 26px for Grow
  background-color: var(--body-bg);
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fake-link {
  color: var(--bs-link-color);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--bs-link-hover-color);
    text-decoration: underline;
  }
}

.nav-link.active {
  box-shadow: $box-shadow;
}

.nowrap {
  white-space: nowrap;
}

.patreon {
  background: #f96854;
  border-color: #f96854;

  &:hover,
  &:focus {
    background: #e05d4c;
    border-color: #e05d4c;
  }

  &:active {
    background: #c75344 !important;
    border-color: #c75344 !important;
  }

  &:active,
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

.pointer {
  cursor: pointer;
}

.rbt-input-multi {
  position: relative;
  overflow: hidden;
  cursor: text;
}

.rounded-bottom-left {
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: 0.5rem !important;
}

.set-icon {
  height: 1.5rem;

  [data-bs-theme='dark'] & {
    filter: invert(100%);
  }
}

.text-yellow {
  color: #ffe600 !important;

  &:hover {
    filter: brightness(110%);
  }
}

@font-face {
  font-family: 'League Gothic';
  src: url('../assets/fonts/LeagueGothic-Regular-VariableFont_wdth.ttf');
}
